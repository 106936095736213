const config = {
    defaultPath: '/',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    storageType: 's3', // local / s3
    storageS3Url: 'https://ajudars-upload.s3.us-east-2.amazonaws.com/',
    APIbaseURL: 'https://rs-back.vercel.app'
};

// APIbaseURL: 'https://rs-back.vercel.app'

// APIbaseURL: 'https://b.ajudecomprandodors.com.br'
// APIbaseURL: 'http://localhost:5000'

export default config;