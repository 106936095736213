import Cookies from 'js-cookie';

export const TOKEN_KEY = 'ajuders/user1';
export const TOKEN_KEY_EMP = 'ajuders/user1Emp';
export const ID_USUARIO = 'ajuders/user2';
export const ID_USUARIO_EMP = 'ajuders/user2Emp';
export const NOME_USUARIO = 'ajuders/user3';
export const NOME_USUARIO_EMP = 'ajuders/user3Emp';

// rememberPassword DEFAULT .F.
export const login = (token, rememberPassword = false) => {
    localStorage.setItem( TOKEN_KEY, token );
}

export const loginEmp = (token, rememberPassword = false) => {
    if(rememberPassword) {
        Cookies.set(TOKEN_KEY_EMP, token, { expires:365 }); // 360 DIAS PARA EXPIRAR
    } else {
        Cookies.set(TOKEN_KEY_EMP, token);  // EXPIRA AO FECHAR O NAVEGADOR
    }
}

export const getToken = () => localStorage.getItem( TOKEN_KEY );
export const getTokenEmp = () => Cookies.get(TOKEN_KEY_EMP);

export const logout = () => {
    localStorage.removeItem( ID_USUARIO );
    localStorage.removeItem( NOME_USUARIO );
    localStorage.removeItem( TOKEN_KEY );
}
export const logoutEmp = () => {
    localStorage.removeItem( ID_USUARIO_EMP );
    localStorage.removeItem( NOME_USUARIO_EMP );
    Cookies.remove( TOKEN_KEY_EMP );
}

export const setUserId = id => localStorage.setItem( ID_USUARIO, id );
export const getUserId = () => localStorage.getItem( ID_USUARIO );

export const setUserIdEmp = id => localStorage.setItem( ID_USUARIO_EMP, id );
export const getUserIdEmp = () => localStorage.getItem( NOME_USUARIO_EMP );

export const setUserName = nome => localStorage.setItem( NOME_USUARIO, nome );
export const getUserName = () => localStorage.getItem( NOME_USUARIO );

export const setUserNameEmp = nome => localStorage.setItem( NOME_USUARIO_EMP, nome );
export const getUserNameEmp = () => localStorage.getItem( NOME_USUARIO_EMP );
