import axios from 'axios';
import config from '../config';
const TOKEN_KEY = 'ajuders/user1';

const api = axios.create({
    baseURL: config.APIbaseURL
})

const cookieToken = localStorage.getItem(TOKEN_KEY);

if(cookieToken && cookieToken !== undefined){
    api.defaults.headers.common['authorization'] = `Bearer ${cookieToken}`;
}

export default api;