import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import RoutesGroup from './routes'; //-> uso <RoutesGroup />

// defaultTheme OLD
import themes from './themes';
//<ThemeProvider theme={themes()}> OLD

//--> Ajuste
//import themes from './views/modules/theme';
//<ThemeProvider theme={themes}>
 
// project imports
import NavigationScroll from './layout/NavigationScroll';

import Notification from './components/Notification';
import Dialog from './components/Dialog';
import CookieConsent from "react-cookie-consent";

//<ThemeProvider theme={themes()}> OLD

// ==============================|| APP ||============================== //
//<RoutesGroup />
const App = () => {

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes()}>
                <CssBaseline />
                <Notification />
                <Dialog />
                <NavigationScroll>
                    <RoutesGroup />
                </NavigationScroll>
                <CookieConsent
                    buttonText="Prosseguir"
                    cookieName="ajudecomprandodorscookieconsent"
                    expires={30}
                    style={{
                        fontSize: "18px",
                        padding: "25px"
                    }}
                    buttonStyle={{
                        fontSize: "18px"
                    }}
                >
                    Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua experiência. Ao seguir utilizando nossa plataforma, você está ciente dessa funcionalidade.
                </CookieConsent>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;