import { createTheme } from '@mui/material/styles';

// assets
import colors from '../assets/scss/_themes-vars.module.scss';
import colorsDark from '../assets/scss/_themes-vars.moduleDark.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';

import { useValue } from '../context/ContextProvider';

export const Theme = () => {
    const { state: { colorMode } } = useValue();
    const color = colors;
    const colorDark = colorsDark;

    const themeOption = {
        customization: { navType:"light"},
        colors: color,
        heading: color.grey900,
        paper: color.paper,
        backgroundDefault: color.paper,
        background: color.primaryLight,
        darkTextPrimary: color.grey700,
        darkTextSecondary: color.grey500,
        textDark: color.grey900,
        menuSelected: color.secondaryDark,
        menuSelectedBack: color.secondaryLight,
        divider: color.grey200,
        borderRadius: 12,
        fontFamily: "'Roboto', sans-serif"
    };
    const themeDark = {
        customization: { navType:"dark"},
        colors: colorDark,
        heading: color.darkTextTitle, // Título - Fonte
        paper: color.darkPaper, // Conteiner da Page
        backgroundDefault: color.darkBackground,
        background: color.darkBackground,
        darkTextPrimary: color.darkTextPrimary,
        darkTextSecondary: color.darkTextSecondary,
        textDark: color.darkTextTitle,
        menuSelected: color.darkSecondaryMain,
        menuSelectedBack: color.darkLevel2,
        divider: color.grey700,
        borderRadius: 12,
        fontFamily: "'Roboto', sans-serif",
    };

    const themeOptions = {
        direction: 'ltr',
        palette: themePalette(colorMode === 'light'? themeOption : themeDark),
        mixins: {
            toolbar: {
                minHeight: '48px',
                padding: '16px',
                '@media (min-width: 600px)': {
                    minHeight: '48px'
                }
            }
        },
        typography: themeTypography(colorMode === 'light'? themeOption : themeDark)
    };

    const themes = createTheme(themeOptions);
    themes.components = componentStyleOverrides(colorMode === 'light'? themeOption : themeDark);

    return themes;
};

export default Theme;
