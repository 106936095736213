import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import Loadable from '../ui-component/Loadable';
import MinimalLayout from '../layout/MinimalLayout';
import NavMotion from '../layout/NavMotion';
import { ProtectedRoute } from "./ProtectedRouteEmp";




// login option 3 routing
const Home = Loadable(lazy(() => import('../views/Home')));
const Empresa = Loadable(lazy(() => import('../views/Empresa')));
const EmpresaAlteracao = Loadable(lazy(() => import('../views/EmpresaAlteracao')));
const Shared = Loadable(lazy(() => import('../views/Shared')));

const Success = Loadable(lazy(() => import('../views/Success')));
const SuccessAlt = Loadable(lazy(() => import('../views/SuccessAlt')));
const SuccessDel = Loadable(lazy(() => import('../views/SuccessDel')));

const AuthLoginEmp = Loadable(lazy(() => import('../views/LoginEmp')));
const NotFound = Loadable(lazy(() => import('../views/notFound')));

//const Terms = Loadable(lazy(() => import('../views/Terms')));
//const Privacy = Loadable(lazy(() => import('../views/Privacy')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <NavMotion><Home /></NavMotion>
        },
        {
            path: '/empresa',
            element: <NavMotion><Empresa /></NavMotion>
        },
        {
            path: '/success',
            element: <NavMotion><Success /></NavMotion>
        },
        {
            path: '/successAlt',
            element: <NavMotion><SuccessAlt /></NavMotion>
        },
        {
            path: '/successDel',
            element: <NavMotion><SuccessDel /></NavMotion>
        },
        {
            path: '/empresa/alteracao/:idStore',
            element: <ProtectedRoute><NavMotion><EmpresaAlteracao /></NavMotion></ProtectedRoute>
        },
        {
            path: '/shared/:idStore',
            element: <NavMotion><Shared /></NavMotion>
        },
        {
            path: '/login',
            element: <NavMotion><AuthLoginEmp /></NavMotion>
        },
        {
            path: '*',
            element: <Navigate to="/404" replace />,
        },
        {
            path: '/404',
            element: <NotFound />
        }
    ]
};

export default AuthenticationRoutes;
