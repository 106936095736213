import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    ButtonBase,
    Tooltip,
    Fade    
} from '@mui/material';

// assets
import { IconArrowsMaximize, IconArrowsMinimize } from '@tabler/icons';


const Section = () => {
    const theme = useTheme();
    const [isFullscreen, setIsFullscreen] = React.useState(false);

    function toggleFullscreen() {
        if (
          !document.fullscreenElement &&
          /* alternative standard method */ !document.mozFullScreenElement &&
          !document.webkitFullscreenElement
        ) {
            setIsFullscreen(true);
          // current working methods
          if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen()
          } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen()
          } else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen(
              Element.ALLOW_KEYBOARD_INPUT
            )
          }
        } else { 
          setIsFullscreen(false);
          if (document.cancelFullScreen) {
            document.cancelFullScreen()
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen()
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen()
          }
        }
      }
    


    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 2,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <Tooltip
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 500 }}
                    title= { !isFullscreen? 'Maximizar':'Minimizar' }
                    >
                    <ButtonBase sx={{ borderRadius: '12px' }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.secondary.light,
                                color: theme.palette.secondary.dark,
                                '&[aria-controls="menu-list-grow"],&:hover': {
                                    background: theme.palette.secondary.dark,
                                    color: theme.palette.secondary.light
                                }
                            }}
                            aria-haspopup="true"
                            onClick={() => {
                                toggleFullscreen()
                              }}
                            color="inherit"
                        >
                           {!isFullscreen
                                ?<IconArrowsMaximize stroke={1.5} size="1.3rem" />
                                :<IconArrowsMinimize stroke={1.5} size="1.3rem" />
                           }

                        </Avatar>
                    </ButtonBase>
                </Tooltip>
            </Box>
        </>
    );
};

export default Section;
