import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { alpha, styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Header from '../Header';
import Sidebar from './Sidebar';
//import { drawerWidth } from '../../themes/constant';

import { useValue } from '../../context/ContextProvider';

import { AnimatePresence } from "framer-motion";
import { useLocation } from "react-router-dom";

const drawerWidth = 260;
// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
  }));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const location = useLocation();

    const { dispatch, state: { opened } } = useValue();

    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    const handleLeftDrawerToggle = () => {
        dispatch({ type: 'SET_MENU', payload: !opened });
    };

    useEffect(() => {
        dispatch({ type: 'SET_MENU', payload: !matchDownMd });
    }, [matchDownMd, dispatch]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: opened ? theme.transitions.create('width') : 'none'
                }}
            >
                <RootStyle>
                    <Toolbar>
                        <Header handleLeftDrawerToggle={handleLeftDrawerToggle} layout='main' />
                    </Toolbar>
                </RootStyle>
            </AppBar>

            {/* drawer */}
            <Sidebar drawerOpen={opened} drawerToggle={handleLeftDrawerToggle} />
            {/* main content */}
            <Main theme={theme} open={opened}>
                <AnimatePresence mode="wait">
                    <Outlet key={location.pathname}/>
                </AnimatePresence>
            </Main>
        </Box>
    );
};

export default MainLayout;
