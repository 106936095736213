import { useRoutes } from 'react-router-dom';

// routes
//import InitRoutes from './InitRoutes';
import AdminRoutes from './AdminRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import PageRoutes from './PageRoutes';

// ==============================|| ROUTING RENDER ||============================== //
export default function ThemeRoutes() {
    return useRoutes([PageRoutes,AuthenticationRoutes,AdminRoutes]);
}