import { lazy } from 'react';

// project imports
import MainLayout from '../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import { ProtectedRoute } from "./ProtectedRoute";
import NavMotion from '../layout/NavMotion';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard')));

const Store = Loadable(lazy(() => import('../views/store')));
const StoreForm = Loadable(lazy(() => import('../views/store/form')));

const Category = Loadable(lazy(() => import('../views/category')));
const CategoryForm = Loadable(lazy(() => import('../views/category/form')));


// ==============================|| MAIN ROUTING ||============================== //

const ViabilityRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/dashboard',
            element: <ProtectedRoute><NavMotion><DashboardDefault /></NavMotion></ProtectedRoute>
        },
        {
            path: '/store',
            element: <ProtectedRoute><NavMotion><Store /></NavMotion></ProtectedRoute>
        },
        {
            path: '/store/:mode/:idStore?',
            element: <ProtectedRoute><NavMotion><StoreForm /></NavMotion></ProtectedRoute>
        },
        {
            path: '/category',
            element: <ProtectedRoute><NavMotion><Category /></NavMotion></ProtectedRoute>
        },
        {
            path: '/category/:mode/:idCategory?',
            element: <ProtectedRoute><NavMotion><CategoryForm /></NavMotion></ProtectedRoute>
        },
        
    ]
};

export default ViabilityRoutes;
