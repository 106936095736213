const reducer = (state, action) => {
  switch (action.type) {

    case 'UPDATE_ALERT':
      return { ...state, alert: action.payload };

    case 'UPDATE_DIALOG':
      return { ...state, dialog: action.payload };
  
    case 'UPDATE_USER':
      return { ...state, currentUser: action.payload };

    case 'MENU_OPEN':
      return { ...state, isOpen: [action.payload] };
  
    case 'SET_MENU':
      return { ...state, opened: action.payload };

    case 'SET_COLOR_MODE':
      localStorage.setItem('ajuders/colorMode', action.payload);
      return { ...state, colorMode: action.payload };

    case 'SET_CURRENT_RECORD':
        return { ...state,  currentRecord: action.payload};
        
    default:
      throw new Error('Ação provider não encontrada!');
  }
};

export default reducer;
