import { createContext, useContext, useReducer } from 'react';
import reducer from './reducer';

const initialState = {
  currentUser: null,
  alert: { open: false, severity: 'info', message: '' },
  dialog: { open: false, message: '', button1: 'OK', button2: 'CANCELAR' },
  colorMode: localStorage.getItem('ajuders/colorMode') || 'light',
  isOpen: [], // for active default menu
  opened: true,
  currentRecord: { isNew: true, headerTitle: '', id: '' },
};

const Context = createContext(initialState);

export const useValue = () => {
  return useContext(Context);
};

const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export default ContextProvider;
