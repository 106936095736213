// assets
import { IconDashboard, IconCategory, IconBuildingStore } from '@tabler/icons';  // https://tabler.io/icons
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const admin = {
    id: 'admin',
    title: 'Admin',
    type: 'group',
    children: [
        {
            id: 'Dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: IconDashboard
        },
        {
            id: 'Category',
            title: 'Categorias',
            type: 'item',
            url: '/category',
            icon: IconCategory
        },
        {
            id: 'Store',
            title: 'Empresas',
            type: 'item',
            url: '/store',
            icon: IconBuildingStore
        },
    ]
};

export default admin;
