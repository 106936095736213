import React from 'react';
import { Dialog, DialogActions, DialogContentText, DialogContent, Button } from '@mui/material';
import { useValue } from '../context/ContextProvider';
import withRoot from '../views/modules/withRoot';


const DialogBox = () => {
  const { state: { dialog } } = useValue();

  return (
    <Dialog open={dialog.open}>
        <DialogContent>
          <DialogContentText color='primary'>{dialog.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' onClick={dialog.click1}>{dialog.button1}</Button>
          <Button color='secondary' onClick={dialog.click2}>{dialog.button2}</Button>
        </DialogActions>
    </Dialog>
  );
};

export default withRoot(DialogBox);
