import axios from 'axios';
import config from '../config';
import Cookies from 'js-cookie';

const apiEmp = axios.create({
    baseURL: config.APIbaseURL
})

if(Cookies.get('ajuders/user1Emp'))
    apiEmp.defaults.headers.common['authorization'] = `Bearer ${Cookies.get('ajuders/user1Emp')}`;

export default apiEmp;