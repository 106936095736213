import { useEffect, useState } from 'react';
import { Navigate } from "react-router-dom";

import { logoutEmp, getTokenEmp } from '../services/auth';
import apiEmp from '../services/apiEmp';

export const ProtectedRoute = ({ children }) => {
    const [ redirect, setRedirect ] = useState(false);


    useEffect(() => {
        async function verify(){
            
            const Token = await getTokenEmp();

            if(getTokenEmp() === undefined){
                logoutEmp();
                setRedirect(true);
                return;
            }

            var isLogged = await apiEmp.get('/api/users/checktokenEmp', {params:{token:getTokenEmp()}});

            if(isLogged.data.status===200){
                apiEmp.defaults.headers.authorization = `Bearer ${Token}`;
                setRedirect(false);
                return;
            }
            
            logoutEmp();
            setRedirect(true);
        }
        verify();
    },[])





//  const { user } = useAuth();
//  if (!user) {
    // user is not authenticated
    return( redirect? <Navigate to="/login" /> : children )
//  }
//  return children;
};