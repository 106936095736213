import { lazy } from 'react';

// project imports
import Loadable from '../ui-component/Loadable';
import MinimalLayout from '../layout/MinimalLayout';
import NavMotion from '../layout/NavMotion';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('../views/authentication/authentication3/Login3')));
//const AuthLogin3Emp = Loadable(lazy(() => import('../views/authentication/authentication3/Login3Emp')));

//const AuthRegister3 = Loadable(lazy(() => import('../views/authentication/authentication3/Register3')));
//{
//    path: '/login',
//    element: <NavMotion><AuthLogin3Emp /></NavMotion>
//}
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/admin',
            element: <NavMotion><AuthLogin3 /></NavMotion>
        },

    ]
};

export default AuthenticationRoutes;
