import { Outlet } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";
import { useLocation } from "react-router-dom";

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
    const location = useLocation();
        
    return (
        <AnimatePresence mode="wait">
            <Outlet key={location.pathname}/>
        </AnimatePresence>
    );
};


export default MinimalLayout;
