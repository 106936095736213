import { useEffect, useState } from 'react';
import { Navigate } from "react-router-dom";

import { logout, getToken } from '../services/auth';
import api from '../services/api';

export const ProtectedRoute = ({ children }) => {
    const [ redirect, setRedirect ] = useState(false);


    useEffect(() => {
        async function verify(){
            
            const Token = await getToken();

            if(getToken() === undefined){
                logout();
                setRedirect(true);
                return;
            }

            var isLogged = await api.get('/api/users/checktoken', {params:{token:getToken()}});

            if(isLogged.data.status===200){
                api.defaults.headers.authorization = `Bearer ${Token}`;
                setRedirect(false);
                return;
            }
            
            logout();
            setRedirect(true);
        }
        verify();
    },[])





//  const { user } = useAuth();
//  if (!user) {
    // user is not authenticated
    return( redirect? <Navigate to="/admin" /> : children )
//  }
//  return children;
};